import React from 'react'
import { PageProps } from 'gatsby'
import ServiceContent from '../../components/ServiceContent'
import Button from '../../components/Button'
import { magasinLink } from '../../data/menu'
import ServiceBanner from '../../components/ServiceBanner'
import { servicesBreadcrumbElement } from '.'
import { LayoutProps } from '../../components/Layout.context'
import { Helmet } from 'react-helmet'

const title = 'Reprise & recyclage'

const RachatRecyclagePage: React.FC<PageProps> = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Chez Save, on aime voir vos vieux téléphones trainer dans vos tiroirs ! Faites un geste pour la planète (et pour vous) recyclez-le ou revendez-le nous !"
        />
      </Helmet>

      <ServiceBanner
        title={title}
        background="/images/services/6_rachat_et_recyclage/rachat-recyclage-banner.jpg"
        parallax
      />

      <ServiceContent>
        <ServiceContent.Title>
          Qu'est-ce que ça peut bien faire ?
        </ServiceContent.Title>
        <ServiceContent.Paragraph>
          Si comme 45% des français, vous laissez vos vieux téléphones dormir au
          fond d'un tiroir, en vous disant "on ne sait jamais, ça peut toujours
          servir" ... STOP ! Il est temps de penser un peu à notre précieuse
          planète ! Car les petits gestes sont souvent à l'origine des grands
          changements !
        </ServiceContent.Paragraph>
        <ServiceContent.Paragraph>
          Surtout que dans certains cas, ce bon geste pour la planète peut vous
          permettre de gagner ce qui vous manquait justement pour acheter ce
          smartphone reconditionné qui vous fait de l'oeil !
        </ServiceContent.Paragraph>
        <ServiceContent.Paragraph>
          N'attendez plus, venez déposer tous vos vieux smartphones inutilisés
          dans le magasin Save le plus proche de chez vous !
        </ServiceContent.Paragraph>
        <Button.ExternalLink href={magasinLink}>
          Trouver mon magasin
        </Button.ExternalLink>
        <ServiceContent.Title>Le recyclage en lui-même</ServiceContent.Title>
        <ServiceContent.Paragraph>
          Pour recycler les déchets électroniques, des procédures sont mises en
          place afin de garantir leur bon traitement. C'est ce qu'on appelle les
          Déchets d'équipements électriques et électroniques ou D3E. Afin
          d'assurer le recyclage de ces déchets et limiter l'impact sur notre
          belle planète, nous travaillons avec les meilleurs du domaine pour
          assurer le recyclage des D3E et également la sécurité de nos employés.
        </ServiceContent.Paragraph>
        <ServiceContent.Title>Piles et batteries usagées</ServiceContent.Title>
        <ServiceContent.Paragraph>
          Notre vocation nous pousse à ne pas nous limiter uniquement aux
          déchets issus de la téléphonie, c'est la raison pour laquelle vous
          pouvez nous apporter vos piles ou batteries usagées, nous nous assurerons ainsi de leur donner une fin de vie digne !
        </ServiceContent.Paragraph>
      </ServiceContent>
    </>
  )
}

const layoutProps: LayoutProps = {
  title:
    'Vous voulez revendre ou recycler votre ancien smartphone, confiez-le nous !',
  breadcrumb: [servicesBreadcrumbElement, { label: title }],
  showHighlights: true,
}

export default Object.assign(RachatRecyclagePage, {
  layoutProps,
})
